

import SAWO from 'sawo'

import React, { useState, useEffect } from 'react'

import {Redirect, } from 'react-router-dom'

function Login() {

    const [user, setUser] = useState({})
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    

    useEffect(() => {
        const onLoginSuccess = async (userData) => {
            setUser(userData)
            setIsLoggedIn(true)
            sessionStorage.setItem('user_id', userData.user_id)
            
        }

        const sawoConfig = {
            containerID: "sawo-form",
            identifierType: "email",
            apiKey: "cf76f342-b834-4110-8110-d6296840cfd6",
            onSuccess: onLoginSuccess,
        }

        let sawo = new SAWO(sawoConfig)


        if(!isLoggedIn) { sawo.showForm() } 

    }, [isLoggedIn])

    if(isLoggedIn){
        return <Redirect to='/jokes'/>
    }
    return (
        <div className='container'>
            <h3 className='center-align'>Chuck Norris Jokes</h3>
            <div id='sawo-form' style={{width: '40%', margin: 'auto', height: '300px'}}>
            </div>
        </div>
    )
}

export default Login
