import Joke from "./components/Joke";
import {
  BrowserRouter as Router, Route, Switch
} from 'react-router-dom'
import Login from "./components/Login";

function App() {
  return (
    
    <Router>
        <Route path='/jokes' component={Joke} />
        <Route exact path='/' component={Login} />
    </Router>
  
  );
}

export default App;
